import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'MOO',
    tokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    contractAddress: {
      97: '',
      56: '0x29B6A4f6cd7Aa15D56eBCD00cB561ec471133191',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  
  {
    sousId: 19,
    tokenName: 'BNB',
    tokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    contractAddress: {
      97: '',
      56: '0x326D754c64329aD7cb35744770D56D0E1f3B3124',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '0.0041',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
  },
  
  {
    sousId: 20,
    tokenName: 'MOO',
    tokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    stakingTokenName: QuoteToken.BNB,
    stakingLimit: 10,
    contractAddress: {
      97: '',
      56: '0x555Ea72d7347E82C614C16f005fA91cAf06DCB5a',
    },
    poolCategory: PoolCategory.BINANCE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
  },
  // {
  //   sousId: 19,
  //   tokenName: 'BNB',
  //   tokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '',
  //     56: '0x326D754c64329aD7cb35744770D56D0E1f3B3124',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://pancakeswap.finance/',
  //   harvest: true,
  //   tokenPerBlock: '0.0041',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 18,
  //   tokenName: 'BLK',
  //   tokenAddress: '0x63870a18b6e42b01ef1ad8a2302ef50b7132054f',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '',
  //     56: '0x42Afc29b2dEa792974d1e9420696870f1Ca6d18b',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://blink.wink.org',
  //   harvest: true,
  //   tokenPerBlock: '23.14',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 6,
  // },
  // {
  //   sousId: 17,
  //   tokenName: 'BLK',
  //   tokenAddress: '0x63870a18b6e42b01ef1ad8a2302ef50b7132054f',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '',
  //     56: '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://blink.wink.org',
  //   harvest: true,
  //   tokenPerBlock: '23.14',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 16,
  //   tokenName: 'UNFI',
  //   tokenAddress: '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '',
  //     56: '0xFb1088Dae0f03C5123587d2babb3F307831E6367',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://unifiprotocol.com',
  //   harvest: true,
  //   tokenPerBlock: '0.02893',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 15,
  //   tokenName: 'TWT',
  //   tokenAddress: '0x4b0f1812e5df2a09796481ff14017e6005508003',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '',
  //     56: '0x9c4EBADa591FFeC4124A7785CAbCfb7068fED2fb',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '5',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 14,
  //   tokenName: 'HARD',
  //   tokenAddress: '0xf79037f6f6be66832de4e7516be52826bc3cbcc4',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '',
  //     56: '0x90F995b9d46b32c4a1908A8c6D0122e392B3Be97',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://hard.kava.io',
  //   harvest: true,
  //   tokenPerBlock: '0.346',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 6,
  // },
  // {
  //   sousId: 13,
  //   tokenName: 'bROOBEE',
  //   tokenAddress: '0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '',
  //     56: '0xdc8c45b7F3747Ca9CaAEB3fa5e0b5FCE9430646b',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: 'https://roobee.io/',
  //   harvest: true,
  //   tokenPerBlock: '12.5',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 12,
  //   tokenName: 'STAX',
  //   tokenAddress: '0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0xFF02241a2A1d2a7088A344309400E9fE74772815',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: 'http://stablexswap.com/',
  //   harvest: true,
  //   tokenPerBlock: '0.2',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 11,
  //   tokenName: 'NAR',
  //   tokenAddress: '0xa1303e6199b319a891b79685f0537d289af1fc83',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0xDc938BA1967b06d666dA79A7B1E31a8697D1565E',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: 'https://narwhalswap.org/',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 10,
  //   tokenName: 'NYA',
  //   tokenAddress: '0xbfa0841f7a90c4ce6643f651756ee340991f99d5',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x07a0A5B67136d40F4d7d95Bc8e0583bafD7A81b9',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: 'https://nyanswop.org/',
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '10',
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 9,
  //   tokenName: 'CTK',
  //   tokenAddress: '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0x21A9A53936E812Da06B7623802DEc9A1f94ED23a',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.certik.foundation/',
  //   harvest: true,
  //   tokenPerBlock: '0.5',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 6,
  // },
  // {
  //   sousId: 8,
  //   tokenName: 'TWT',
  //   tokenAddress: '0x4b0f1812e5df2a09796481ff14017e6005508003',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xe7f9A439Aa7292719aC817798DDd1c4D35934aAF',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 7,
  //   tokenName: 'INJ',
  //   tokenAddress: '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xcec2671C81a0Ecf7F8Ee796EFa6DBDc5Cb062693',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://injectiveprotocol.com/',
  //   harvest: true,
  //   tokenPerBlock: '0.25',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 6,
  //   tokenName: 'CTK',
  //   tokenAddress: '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0xF35d63Df93f32e025bce4A1B98dcEC1fe07AD892',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.certik.foundation/',
  //   harvest: true,
  //   tokenPerBlock: '0.5',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 5,
  //   tokenName: 'CTK',
  //   tokenAddress: '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x3B9B74f48E89Ebd8b45a53444327013a2308A9BC',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.certik.foundation/',
  //   tokenPerBlock: '0.5',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 6,
  // },
  // {
  //   sousId: 4,
  //   tokenName: 'SXP',
  //   tokenAddress: '0x47bead2563dcbf3bf2c9407fea4dc236faba485a',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0xD32B30b151a6aDB2e0Fa573a37510C097DaBD2F3',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://swipe.io/',
  //   harvest: true,
  //   tokenPerBlock: '0.5',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 3,
  //   tokenName: 'INJ',
  //   tokenAddress: '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0x92E8CeB7eAeD69fB6E4d9dA43F605D2610214E68',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://injectiveprotocol.com/',
  //   harvest: true,
  //   tokenPerBlock: '0.25',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 2,
  //   tokenName: 'ALPHA',
  //   tokenAddress: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x73c83bd1646991cBca3e6b83ca905542FE07C57A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://alphafinance.io/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'TWT',
  //   tokenAddress: '0x4b0f1812e5df2a09796481ff14017e6005508003',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     56: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: -1,
  //   tokenName: 'XVS',
  //   tokenAddress: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
  //   contractAddress: {
  //     97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  //   },
  //   tokenPerBlock: '0',
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://venus.io/',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
