import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'MOO',
    lpAddresses: {
      97: '',
      56: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 4,
    lpSymbol: 'MOO-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x5FfA2836fCBf82040b245fDb8E2415ff8ef5795A',
    },
    tokenSymbol: 'MOO',
    tokenAddresses: {
      97: '',
      56: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    lpSymbol: 'MOO-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xdc0d9eca104b60f2d388b812dfe2e07cf7d14424',
    },
    tokenSymbol: 'MOO',
    tokenAddresses: {
      97: '',
      56: '0xE264eac10e032E53E508C5146Ab7cc0546a074a3',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  
  {
    pid: 5,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xB246ACFA8429dE38033029A0DF2deD8ec9922EC5',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
]

export default farms
